import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carouseler from "../components/carousel"
import 'bootstrap/dist/css/bootstrap.min.css'


const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Home" />
      <Carouseler />
    </Layout>
  </>
)

export default IndexPage


//cuts

/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/wedding/">Go to page 2</Link> */

      // style={{backgroundColor: '#FFCBA4'}}

      //import { Link } from "gatsby"
      //import Image from "../components/image"