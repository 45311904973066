import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap'
import styles from '../styles/carousel.module.css'

const items = [
    {
        src: 'https://lh3.googleusercontent.com/HqI4SM7bLkm8UUXGC_3sBi4uxQb-Os3MWWWHVx-t6FPlKtdH93V49jpPny8lHKgIFLJoOhvE411YqrOmLqoULFePrTr5B7k-OQomm1wn2QlESakKKUBqEvORyWtBI54cYtBq8KaIVxk=d',
        altText: 'Yumka',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/BM4J9mVA8VypRnvr-IVfYhv-Sioebm8YiDaz5XMP_ThvpWDy-74pIjqfzOomnvsdyb398Wh9GSRMIKdEmz02LI3VYsnyp4EkqJLubPIeLtJfgDcJm8SON0YVhoz1m5uGAaagySPexZI=d',
        altText: 'Yumka2',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/iy2wXN79l1MqU5aTJyjje1kM5O9XEqovQnwsYw24_n_l5nOApnKn2zB-FAw0bQYHvb6xNwyEg35FfC7N5nSdbAarmZv5JcJWqBTmd1s8pXWLmXVly5QL37wRTnPqBRlAQm4Dm_PjHV4=d',
        altText: 'Tapi',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/gAJSVjcEUVPSEyI9sJ99u8X2E9fMU1H2daAh9sTcenKjjlRmkEPN2xBAhN7aq-I86LafK_N5d2_QpfrM7d9EtW6TZJZjnMWX9nk_hXBJleOUOniL-HX3YZLql-frDWwNupDfk_hJ5pc=d',
        altText: 'Momma Ibis',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/SQvQlVxRNVaygE5r997nv9_ekXCi7W0CRuWhAaT1RPqWlW9bKOTtrr_N8WBQwxZJ6NA1bC5jLTkT0wpqEqgc71eYP9hHW6tqL5IABousNc7qMzIjj-VWYyVZwuc_SH74dhmjY89-8ds=d',
        altText: 'Kissing',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/EZHqx4j7S3h2V-czl7rukuYSKWsxEm1wYdHPjoRIGsJYeyhEhtSEQ9oJ--KOZxUYst0kTnjg7ZPEOdrF2152UJrEZ-gz1BpAlvV5QEhhuQmJT7YyULUJiZ-5nxnvLL6tWRI2LZHQI6Y=d',
        altText: 'Baseball',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/CI2sPj7AixNhbbVm8G9_9wcKlYXOCJpDnmGkMbm4mFp7Rs2Vj74cmPIZ1PVsa9K9DwUVN2zoc1Wm-upp5iegzDAZ_a3Oa_KOggKXLOKx5ugIQ8_e9IuEcXs_Zf0YSLroEKVBzFGJr7s=d',
        altText: 'Mtns kissing',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/GsYI1BKoUFWJPcGU-9cXm-IyOyQWw6_bTKfj5zVjfMhfwGBFHaxjBmpXeFUBUi4t4vNoCxeXj8F8sCSQV7-Fj7kTrI8Qpa8pl2UZb1CknPtdxMingSNHnZCCD0LRXoNO3IVJsBGv8Zc=d',
        altText: 'Kentucky flowers',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/YF-9haflN23AnrgDnTL4ALzVnpzD0DOMwLb1GlKczh05Kosc5GW480xQboWbU9a9ggTXjsQFb3HbJH6ldVzrPbf3oXMb-kFTT25FGdbypDKKkry3qpdUDOLhWo9s1SLpa-iztH1DXGc=d',
        altText: 'Racetrack',
        header: '',
        caption: ''
    },
    {
        src: 'https://lh3.googleusercontent.com/P-57tQPNvw6mKXBS30mUED1mh8a7rx_63w7v7t1sNgeJqBEC7VyQrcv99ig3N6v8MOI18KQHZ4NkcgsndLx7_qMG-ZdJlOoNEicYk-qZi06ifZYW6I5rsxeTVkPfRaC1xVzcJyXPdEo=d',
        altText: 'Relaxing',
        header: '',
        caption: ''
    }
];

const Example = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
                style={{ backgroundColor: '#FFCBA4' }}
            >
                <img style={{ display: 'block', margin: 'auto', maxHeight: '651px' }} src={item.src} alt={item.altText} />
                <div className='TextCont'>
                    <CarouselCaption captionText={item.caption} captionHeader={item.header} />
                </div>

            </CarouselItem>
        );
    });



    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >

            <CarouselIndicators className={styles.ind} items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}

            <CarouselControl className={styles.prev} direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl className={styles.next} direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
}

export default Example;
